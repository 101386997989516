<template  lang="pug">
	v-container(class="")
		v-row(justify="center")
			v-col( cols="12")
				v-card(class=" p-0" outlined)
					v-card-title(class="pb-0")
						h1(align="center" class="title blue-600--text ") Porcentaje completitud atributos por clase
					v-card-text(class="pb-0")
						v-container
							v-row( class="align-end" )
								v-col(cols="12"  md="2" offset-md="7")
									v-select(
										label="Área"
										v-model="areaSelected"
										:items="inputAreaOptions"
										:disabled="dataLoading"
										clearable)
								v-col(cols="12"  md="2")
									v-text-field(
										label="Búscar"
										:disabled="dataLoading"
										v-model="search")

								v-col(cols="12"  md="1" class="align-self-center justify-end")
									v-tooltip(top class="")
										template(v-slot:activator="{ on, attrs }")
											v-btn(color="green" class="white--text mr-2" v-bind="attrs" v-on="on" @click="downloadExcel"  :loading="excelLoading" :disabled="excelLoading" small) xlsx
												v-icon(dark) mdi-download
										span Descargar Excel

									//-v-tooltip(top class="")
										template(v-slot:activator="{ on, attrs }")
											v-btn(color="blue-500" class="white--text mr-2" v-bind="attrs" v-on="on" @click="downloadCSV"  :loading="CSVLoading" :disabled="CSVLoading" small) csv
												v-icon(dark) mdi-download
												
										span Descargar CSV

									//-v-tooltip(top)
										template(v-slot:activator="{ on, attrs }")
											v-btn(color="blue-500" v-bind="attrs" v-on="on" class="white--text" @click="filter"  :loading="dataLoading" :disabled="dataLoading") 
												v-icon(dark) mdi-filter
										span Filtrar

							v-row
								v-col
									v-data-table(
										:headers="headers"
										:items="dataTableComputed"
										:search="search"
										:loading="dataLoading"
										:items-per-page="50"
										class="elevation-0")
										template(v-slot:item.skus="{ item }")
											div(class="wrapper-skus") {{ item.skus }}
										
							



</template>
<script>
	import PorcentajeCompletitudService from '../../../services/PorcentajeCompletitudService'
	const porcentajeCompletitudService = new PorcentajeCompletitudService();
	export default {
		name: 'PorcentajeCompletitud',
		data() {
			return {
				headers: [
					{ 
						value: 'area',
						text: 'área',
						sortable: false,
                        valign: "top"
					},
					{ 
						value: 'clase',
						text: 'Clase',
						sortable: true
					},
					{ 
						value: 'atributo',
						text: 'atributo',
						sortable: false
					},
					{ 
						value: 'porcentaje',
						text: 'puntaje porcentaje',
						sortable: true
					},
					{ 
						value: 'skus',
						text: 'SKUs',
						sortable: false,
                        width: 400
					},
				],

				dataLoading: false,
                CSVLoading: false,
				excelLoading: false,
				dataTable: [],
				areaSelected: '',
				inputAreaOptions: ['Ropa', 'Muebles'],
				search: '',

			}
		},
		computed: {
			dataTableComputed() {
				if(this.areaSelected == 'Ropa') {
					return this.dataTable.filter(item => {
						return item.area === 2
					});
				} else if(this.areaSelected == 'Muebles') {
					return this.dataTable.filter(item => {
						return item.area === 3
					});
				} else {
					return this.dataTable;
				}
			},
		},
		methods:{
			init() {
                this.fetchData()
			},
            fetchData() {
                this.dataLoading = true;
                porcentajeCompletitudService
                    .fetchAll()
                    .then(response => {
                        this.dataTable = response.data.data
                    })
                    .finally(()=>{
                        this.dataLoading = false;
                    });
            },
			downloadExcel() {
				this.excelLoading = true;
				porcentajeCompletitudService.downloadExcel()
				.then(response => {
					var blob = new Blob([response.data], { type: response.headers['content-type'] });
					var downloadUrl = URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = downloadUrl;
					a.download = 'porcentajecompletitud.xlsx';
					document.body.appendChild(a);
					a.click();
					// remove element
					a.remove()
				})
				.catch(errors => {
					console.log(errors.error);
				})
				.finally(() =>{
					this.excelLoading = false;
				});
			},
			downloadCSV() {
				this.CSVLoading = true;
				porcentajeCompletitudService.downloadCSV()
				.then(response => {
					var blob = new Blob([response.data], { type: response.headers['content-type'] });
					var downloadUrl = URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = downloadUrl;
					a.download = 'porcentajecompletitud.csv';
					document.body.appendChild(a);
					a.click();
					// remove element
					a.remove()
				})
				.catch(errors => {
					console.log(errors.error);

				})
				.finally(() =>{
					this.CSVLoading = false;
				});
			}
		},
        mounted(){
			this.init();
		},	
	}
</script>


<style scoped>
.wrapper-skus {
	max-height: 160px;
    overflow-y: auto;
    padding: 10px 0px;
}
</style>