import BackendAuthService from "./BackendAuthService";

export default class PorcentajeCompletitud extends BackendAuthService {

	constructor() {
		super();
	}

    // fetch all data from ecommerce-bi.ScorePDP.pruebas_porcentaje_atributos table
	fetchAll() {
		return this.httpCall('api/v1/porcentajecompletitus/pruebas-porcentaje-atributos', {
			method: 'GET'
		});
	}

	// Download Excel file from ecommerce-bi.ScorePDP.pruebas_porcentaje_atributos table 
	downloadExcel() {
		return this.httpCall('api/v1/porcentajecompletitus/download-excel', {
			method: 'POST',
			responseType: 'blob'
		});
	}
	// Download CSV file from ecommerce-bi.ScorePDP.pruebas_porcentaje_atributos table 
	downloadCSV() {
		return this.httpCall('api/v1/porcentajecompletitus/download-csv', {
			method: 'POST',
			responseType: 'blob'
		});
	}

	
}
